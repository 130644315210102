import React from "react";
import styles from "../../styles/reuters-styles/reuters.module.scss";
import ProvidersHeader from "../../components/providers-components/providersHeader";
import FirstSection from "../../components/weatherBug-components/firstSection";
import SecondSection from "../../components/providers-components/secondSection";
import LineDivider from "../../components/providers-components/lineDivider";
import NewsProvidersCarousel from "../../components/providers-components/newsProvidersCarousel";
import FreeDownload from "../../components/providers-components/freeDownload";
import WeatherBugFreeDownloadImage from "../../components/imageComponents/weatherBugFreeDownload";
import ProvidersFooter from "../../components/providers-components/providersFooter";
import PixelCannon from "../../components/shared/pixelCannon";
import { Helmet } from "react-helmet";
import { PRIVACY_LINK, WEATHERBUG_ALS_INSTALL_LINK, WEATHERBUG_PAGE_TITLE, WEATHERBUG_PIXEL_LINK } from "../../constants";

const Index = () => {
  return (
    <>
      <Helmet>
        <title>{WEATHERBUG_PAGE_TITLE}</title>
      </Helmet>
      <PixelCannon pixelLink={WEATHERBUG_PIXEL_LINK} />
      <div className="container-fluid">
        <div className="row sticky-top">
          <div className={`container-fluid p-0 ${styles.mw1920} ${styles.header}`}>
            <ProvidersHeader btnLink={WEATHERBUG_ALS_INSTALL_LINK} />
          </div>
        </div>
        <div className="row">
          <div className={`container-fluid p-0 ${styles.mw1920}`}>
            <div className={`d-flex flex-wrap ${styles.firstSectionContainer}`}>
              <FirstSection btnLink={WEATHERBUG_ALS_INSTALL_LINK} />
            </div>
            <div className={`d-flex flex-wrap ${styles.secondSectionContainer}`}>
              <SecondSection />
            </div>
          </div>
        </div>
        <div className="row">
          <LineDivider btnLink={WEATHERBUG_ALS_INSTALL_LINK} />
        </div>
        <div className="row">
          <div className={`container-fluid p-0 ${styles.mw1920}`}>
            <div className="d-flex flex-wrap">
              <NewsProvidersCarousel />
            </div>
            <div className="d-flex flex-wrap">
              <FreeDownload
                FreeDownloadImage={WeatherBugFreeDownloadImage}
                btnLink={WEATHERBUG_ALS_INSTALL_LINK}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <ProvidersFooter privacyLink={PRIVACY_LINK} />
        </div>
      </div>
    </>
  );
};

export default Index;
